<template>
  <v-container fluid class="px-1 pa-md-5 py-0">
    <v-row>
      <v-col class="pr-md-0 py-0" :cols="12" :md="4" v-if="!($vuetify.breakpoint.mdAndDown && threadId) || ($vuetify.breakpoint.mdAndUp && threadId)">
        <v-card class="rounded-r-0 list-container" height="100%">
          <v-card-title class="py-3">Messages</v-card-title>
          <v-divider></v-divider>
          <list :messages-list="threads" v-if="threads.length > 0" @fetchMoreThreads="fetchMoreThreads" :loading="$apollo.queries.threads.loading" :allFetched="allThreadsFetch"/>
          <threads-loader v-show="$apollo.queries.threads.loading && threads.length === 0" :count="8" />
        </v-card>
      </v-col>
      <v-col class="pl-md-0 py-0" :md="8" :cols="12">
        <thread v-if="threadId" :current-user="currentUser" :thread-id="threadId" :key="threadId" :current-thread-meta="currentThreadMeta"/>
        <message-placeholder v-if="!$vuetify.breakpoint.smAndDown && !threadId"  />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import List from '../components/Messages/List'
import Thread from '../components/Messages/Thread'
// import MessageLoader from '../components/Utils/MessageLoader'
import ThreadsLoader from '../components/Utils/ThreadsLoader'
import MessagePlaceholder from '../components/Utils/MessagePlaceholder'
const THREADS = require('../graphql/queries/threads.graphql')
export default {
  apollo: {
    threads: {
      query: THREADS,
      variables () {
        return {
          query: {
            number: this.threadsNumber,
            paged: 1
          }
        }
      },
      result (ApolloQueryResult) {
        this.fetchedThreadsCount = ApolloQueryResult.data.threads.length
      }
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    },
    threadId: {
      default: null
    }
  },
  data () {
    return {
      threads: [],
      threadsPage: 1,
      threadsNumber: 10,
      fetchedThreadsCount: 0
    }
  },
  computed: {
    currentThreadMeta () {
      return this.threads.filter(thread => {
        return thread.id === this.threadId
      })[0] || {
        user_id: '#',
        name: 'Direct Messages'
      }
    },
    allThreadsFetch () {
      return this.fetchedThreadsCount < this.threadsNumber
    }
  },
  methods: {
    async fetchMoreThreads () {
      this.threadsPage = Math.ceil(this.threads.length / this.threadsNumber) + 1
      try {
        const res = await this.$apollo.queries.threads.fetchMore({
          // New variables
          variables: {
            query: {
              paged: this.threadsPage,
              number: this.threadsNumber
            }
          },
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return {
              threads: [...previousResult.threads, ...fetchMoreResult.threads]
            }
          }
        })
        this.fetchedThreadsCount = res.data.threads.length
      } catch (e) {
        console.log(e)
        return e
      }
    }
  },
  metaInfo () {
    return {
      title: 'Messages | Gradbee'
    }
  },
  components: {
    List,
    // MessageLoader,
    ThreadsLoader,
    MessagePlaceholder,
    Thread
  }
}
</script>
<style lang="scss" scoped>
</style>
