<template>
  <v-list two-line class="py-0 list">
    <list-item v-for="(messageMeta, i) in messagesList" :key="i" :message-meta="messageMeta" />
    <v-btn @click="$emit('fetchMoreThreads')" block text :loading="loading" v-show="!allFetched">Load More</v-btn>
  </v-list>
</template>

<script>
import ListItem from './ListItem'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    allFetched: {
      type: Boolean,
      default: false
    },
    messagesList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ListItem
  }
}
</script>
