<template>
  <div ref="threadsTrigger">
    <div v-for="num in count" :key="num">
      <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader><v-divider></v-divider>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    count: {
      type: Number,
      default: 1
    }
  }
}
</script>
