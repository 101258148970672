<template>
  <div>
    <v-list-item :to="{name: 'messages', params: {threadId: messageMeta.id}}">
      <v-list-item-avatar>
        <v-img :src="messageMeta.avatar" :lazy-src="messageMeta.avatar"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title :class="{'font-weight-bold': !messageMeta.read }">{{ messageMeta.name }}<span class="caption ml-2">{{ messageMeta.date | moment('from', 'now') }}</span></v-list-item-title>
        <v-list-item-subtitle class="caption" :class="{'font-weight-bold': !messageMeta.read }">{{ messageMeta.message }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="!messageMeta.read" class="">
        <v-icon x-small color="secondary">mdi-circle</v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  props: {
    messageMeta: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
