<template>
  <div class="message-container">
    <v-row :justify="isMe ? 'end' : 'start'">
      <v-col :cols="isMe ? 11 : 12" :md="9" >
        <div :class="isMe ? 'float-right': 'float-left'">
          <div class="d-flex align-end" :class="isMe ? 'justify-end' : ' justify-start'">
            <v-avatar v-if="!isMe" color="grey lighten-1" size="40" class="mr-2"><v-img :src="message.avatar" :lazy-src="message.avatar"></v-img></v-avatar>
            <v-sheet :color="!isMe ? 'secondary lighten-1' : message.date === 'now' ? 'accent lighten-2' : 'accent' " :dark="!isMe" rounded elevation="1" class="message pa-4 d-inline-flex rounded-lg" :class="!isMe ? 'rounded-bl-sm' : 'rounded-br-sm'">
              {{ message.comment }}
            </v-sheet>
          </div>
          <div class="caption mt-1" :class="!isMe ? 'ml-12' : 'text-right'">
            <v-icon x-small>mdi-calendar</v-icon> {{ message.date | moment('from', 'now') }}
            <v-icon x-small v-if="message.date === 'now'">mdi-clock-outline</v-icon>
            <v-icon x-small v-else>mdi-check</v-icon>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    },
    currentUser: {
      type: Object,
      default: () => {}
    },
    margin: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isMe () {
      return this.message.user_id === this.currentUser.ID
    }
  }
}
</script>
<style lang="scss">
  .message-container {
    &:first-child {
      margin-bottom: 2.5rem;
    }
    p {
      line-height: 1.4;
      font-size: 0.85rem;
    }
  }
  .message {
    word-break: break-word;
    p:last-child {
      margin-bottom: 0;
    }
  }
</style>
