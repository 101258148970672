<template>
  <v-card class="rounded-l-0 thread-container">
    <div>
      <v-card-title class="py-3 d-flex justify-space-between">
        <router-link class="user-link text--primary" :to="{name: 'profile', params: {id: currentThreadMeta.user_id}}">{{ currentThreadMeta.name }}</router-link>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on" @click="$emit('fetchThread')"><v-icon>mdi-refresh</v-icon></v-btn>
          </template>
          <span>Fetch Messages</span>
        </v-tooltip>

      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="thread d-flex flex-column-reverse" @click="$refs.messageText.focus()">
        <div :style="{'margin-top': `${margin}px`}"></div>
        <message :message="message" v-for="(message, i) in comments" :key="i" :margin="margin" :current-user="currentUser" />
        <messages-loader v-show="$apollo.queries.comments.loading"/>
        <div class="text-center py-4">
          <v-btn @click="fetchMoreMessages" text outlined v-show="!$apollo.queries.comments.loading">Load More <v-icon>mdi-refresh</v-icon></v-btn>
        </div>
      </v-card-text>
    </div>
    <v-footer padless :fixed="$vuetify.breakpoint.smAndDown">
      <div class="writer-container" ref="writer">
        <v-divider></v-divider>
        <v-sheet class="d-flex">
          <v-textarea v-model.trim="message" solo hide-details class="flex-grow-1 maxH" rows="1" placeholder="Write your message here" no-resize :row-height="2" auto-grow ref="messageText"></v-textarea>
          <v-btn @click.prevent="validate" fab depressed text small class="align-self-center actions">
            <v-icon>mdi-send</v-icon>
          </v-btn>
          <!--        <writer @changeHeight="handleChangeHeight" :thread-id="threadId"/>-->
        </v-sheet>
      </div>
    </v-footer>
  </v-card>
</template>

<script>
import Message from './Message'
import MessagesLoader from '../Utils/MessageLoader'
// import Writer from './Writer'
const ADD_COMMENT = require('../../graphql/mutations/addComment.graphql')
const MESSAGES = require('../../graphql/queries/messages.graphql')
export default {
  apollo: {
    comments: {
      query: MESSAGES,
      variables () {
        return {
          threadId: this.threadId,
          query: {
            number: this.commentsNumber,
            paged: 1
          }
        }
      },
      fetchPolicy: 'network-only',
      result (ApolloQueryResult) {
        // console.log(ApolloQueryResult)
        // this.fetchedThreadsCount = ApolloQueryResult.data.threads.length
      }
    }
  },
  data () {
    return {
      margin: 0,
      message: '',
      commentsPage: 1,
      commentsNumber: 10
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    },
    threadId: {
      default: null
    },
    currentThreadMeta: {
      default: () => {},
      type: Object
    }
  },
  methods: {
    validate () {
      if (this.message) {
        this.submitMessage()
      }
      this.$refs.messageText.focus()
    },
    async fetchMoreMessages () {
      try {
        this.commentsPage++
        await this.$apollo.queries.comments.fetchMore({
          variables: {
            query: {
              paged: this.commentsPage,
              number: this.commentsNumber
            }
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return {
              comments: [...previousResult.comments, ...fetchMoreResult.comments]
            }
          }
        })
      } catch (e) {
        this.commentsPage--
      }
    },
    async submitMessage () {
      this.loading = true
      this.comments.unshift({
        comment: this.message,
        date: 'now',
        user_id: this.currentUser.ID
      })
      try {
        const message = this.message
        this.message = ''
        await this.$apollo.mutate({
          mutation: ADD_COMMENT,
          variables: {
            threadId: this.threadId,
            message: message
          },
          update: (store, { data: { addComment } }) => {
            // should not do this!
            try {
              const data = store.readQuery({
                query: MESSAGES,
                variables: {
                  threadId: this.threadId,
                  query: {
                    number: this.commentsNumber,
                    paged: 1
                  }
                }
              })
              data.comments.shift()
              data.comments.unshift(addComment)
              store.writeQuery({
                query: MESSAGES,
                data
              })
            } catch (e) {
              console.log(e)
              return e
            } finally {
              // this.$emit('onStepChange', 1)
              // await this.$router.push('feed')
            }
            this.loading = false
          }
        })
      } catch (e) {
        console.log(e)
        this.error = e
      } finally {
        this.$refs.messageText.focus()
        this.loading = false
      }
    },
    handleChangeHeight () {
      this.margin = this.$refs.writer.getBoundingClientRect().height
    }
  },
  mounted () {
    this.handleChangeHeight()
  },
  components: {
    // Writer,
    MessagesLoader,
    Message
  },
  watch: {
    message: {
      handler () {
        this.handleChangeHeight()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .user-link {
    text-decoration: none;
  }
</style>
